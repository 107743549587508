import React from "react";
import {Box} from "@mui/material";
import {BackgroundPosition, getCardBackgroundPosition} from "../BackgroundPositionCalculator";
import {CardType} from "../types/CardType";
import {CardColor} from "../types/CardColor";
import {SxProps} from "@mui/system/styleFunctionSx";

interface ChosenShapeCardProps {
    cardType: CardType,
    cardColor?: CardColor,
    isEmpty?: boolean,
}

const ChosenShapeCard = ({cardType, cardColor, isEmpty}: ChosenShapeCardProps) => {

    const backgroundPosition: BackgroundPosition = getCardBackgroundPosition(cardType, cardColor);

    const sxProps: SxProps = {
        backgroundPosition: `${backgroundPosition.x}% ${backgroundPosition.y}%`
    }

    if (isEmpty) {
        sxProps.visibility = "hidden";
    }

    return (
        <Box sx={{margin: "8px"}}>
            <Box className="bga-card" sx={sxProps}/>
        </Box>
    )
}

export default ChosenShapeCard;