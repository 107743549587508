import React, {useEffect, useState} from 'react';
import './App.css';
import {Box, Typography} from "@mui/material";
import TopNav from "./TopNav/TopNav";
import ShapeList from "./Card/ShapeList";
import ColorList from "./Colors/ColorList";
import ChosenCards from "./Card/ChosenCards";
import {getDefaultPencilColors, getPencilColorsFromStorage, PencilColor} from "./types/PencilColor";
import DrawButton from "./DrawButton";
import {DeckHandler} from "./DeckHandler";
import CardSelectionResult from "./types/CardSelectionResult";
import {StationCard} from "./types/StationCard";
import {CardType} from "./types/CardType";

function App() {
    const [roundNumber, setRoundNumber] = useState<number>(1);
    const [isEndOfRound, setIsEndOfRound] = useState<boolean>(false);
    const [pencilColors, setPencilColors] = useState<PencilColor[]>(getPencilColorsFromStorage());
    const [stationNumber, setStationNumber] = useState<number>(0);
    const [selectionResult, setSelectionResult] = useState<CardSelectionResult | null>(null);
    const [selectedCards, setSelectedCards] = useState<StationCard[]>([]);
    const [deckHandler, setDeckHandler] = useState<DeckHandler>(new DeckHandler());

    useEffect(() => {
        localStorage.setItem("pencilColors", JSON.stringify(pencilColors));
    }, [pencilColors])

    const handleResetGame = () => {
        setPencilColors(getDefaultPencilColors());
        setDeckHandler(new DeckHandler());
        setSelectedCards([]);
        setRoundNumber(1)
        setStationNumber(0);
        setIsEndOfRound(false);
        setSelectionResult(null);
    }

    const handleEndOfRound = () => {
        if (roundNumber >= 4) {
            handleResetGame();
        } else {
            deckHandler.resetDeck();
            setRoundNumber(roundNumber + 1);
            setIsEndOfRound(false);
            setStationNumber(0);
            setSelectedCards([]);
            setSelectionResult(null);
        }
    }

    const handleNextStation = (isEndOfRound: boolean) => {
        const selectionResult = deckHandler.getSelection();
        if (selectionResult.selectedCard) {
            const newCards = [
                selectionResult.selectedCard,
                selectionResult.isSplit ? {cardType: CardType.Split} : null
            ].filter((card) => card !== null) as StationCard[];

            setSelectedCards([
                ...(isEndOfRound ? [] : selectedCards),
                ...newCards
            ])
        }

        setIsEndOfRound(selectionResult.isEndOfRound);

        setStationNumber(selectionResult.stationNumber);
        setSelectionResult(selectionResult);
    }

    const handleDrawClicked = () => {
        if (isEndOfRound) {
            handleEndOfRound();
        } else {
            handleNextStation(isEndOfRound);
        }
    }

    const buttonText = isEndOfRound ?
        (roundNumber >= 4 ? "Finish Game" : "Next Round") :
        "Next Station";

    return (
        <Box>
            <TopNav handleResetGame={handleResetGame}/>
            <Box sx={{textAlign: 'center'}}>
                <Typography variant="h3" mt={1}>Round {roundNumber}</Typography>
                <ColorList colors={pencilColors}/>
                <Typography variant="h4" mt={1}>Station {stationNumber} of 5</Typography>
                <ChosenCards selectedCard={selectionResult?.selectedCard} isSplit={selectionResult?.isSplit || false}/>
                <ShapeList selectedCards={selectedCards}/>
                <DrawButton text={buttonText} onDrawClicked={handleDrawClicked}/>
            </Box>
        </Box>
    );
}

export default App;
