import React from "react";
import {BackgroundPosition, getIconCardBackgroundPosition} from "../BackgroundPositionCalculator";
import {Box, useTheme} from "@mui/material";
import {Colors} from "../Theming/Colors";
import {CardColor} from "../types/CardColor";
import {CardType} from "../types/CardType";

type ShapeListCardProps = {
    cardType: CardType,
    cardColor?: CardColor | undefined,
    isSelected: boolean,
}

const ShapeListCard = ({cardType, cardColor, isSelected}: ShapeListCardProps) => {
    const theme = useTheme();
    const backgroundPosition: BackgroundPosition = getIconCardBackgroundPosition(cardType);
    const borderColor = cardColor === CardColor.Pink ? Colors.Pink : Colors.Blue;

    return (
        <Box m={0.5} display="flex" justifyContent="center">
            <Box className="bga-icon-card"
                 sx={{
                     backgroundColor: theme.palette.mode === 'dark' ?  "#CCC" : "#FFF",
                     backgroundPosition: `${backgroundPosition.x}% ${backgroundPosition.y}%`,
                     borderColor: borderColor,
                 }}>
                {isSelected && <Box className="bga-icon-card-selected">X</Box>}
            </Box>
        </Box>
    )
}

export default ShapeListCard;