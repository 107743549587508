import {Box, Menu, MenuItem, useTheme} from "@mui/material";
import React, {MouseEvent, useContext, useState} from "react";
import SettingsIconButton from "./SettingsIconButton";
import ColorModeContext from "../Theming/ColorModeContext";

interface TopNavMenuProps {
    resetGame: () => void
}

const TopNavMenu = ({resetGame}: TopNavMenuProps) => {
    const theme = useTheme()
    const colorModeContext = useContext(ColorModeContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleIconClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }
    const toggleUIMode = () => {
        colorModeContext.toggleColorMode()
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const onResetGame = () => {
        resetGame();
        handleClose();
    }

    return <Box>
        <SettingsIconButton text="Settings" handleIconClick={handleIconClick}/>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            <MenuItem onClick={() => toggleUIMode()}>
                {theme.palette.mode === 'light' ? "Dark mode" : "Light mode"}
            </MenuItem>
            <MenuItem onClick={() => onResetGame()}>
                Reset Game
            </MenuItem>
        </Menu>
    </Box>
}

export default TopNavMenu;