import {AppBar, Box, Toolbar, Typography} from "@mui/material";
import TopNavMenu from "./TopNavMenu";

interface TopNavProps {
    handleResetGame: () => void
}

const TopNav = ({handleResetGame}: TopNavProps) => {
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography variant="h6" color="inherit" sx={{flex: 1}}>
                        Next Station: London
                    </Typography>
                    <TopNavMenu resetGame={handleResetGame} />
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default TopNav;