export enum PencilColor {
    Blue = "blue",
    Green = "green",
    Pink = "pink",
    Purple = "purple",
}

export const getDefaultPencilColors = () =>
    [
        PencilColor.Blue,
        PencilColor.Green,
        PencilColor.Pink,
        PencilColor.Purple,
    ].sort(() => Math.random() - 0.5)

export const getPencilColorsFromStorage = () => {
    const pencilColors = localStorage.getItem("pencilColors")
    if (pencilColors) {
        return JSON.parse(pencilColors)
    }
    return getDefaultPencilColors()
}