import ChosenShapeCard from "./ChosenShapeCard";
import {CardType} from "../types/CardType";
import {Box} from "@mui/material";
import React from "react";
import {StationCard} from "../types/StationCard";

interface ChosenCardsProps {
    selectedCard: StationCard | undefined,
    isSplit: boolean,
}

const ChosenCards = ({selectedCard, isSplit}: ChosenCardsProps) =>
    (
        <Box display="flex" flexDirection="row" justifyContent="center" py="16px">
            {isSplit && <ChosenShapeCard cardType={CardType.Split}/>}
            {selectedCard && <ChosenShapeCard cardType={selectedCard.cardType} cardColor={selectedCard.cardColor}/>}
            {!selectedCard && <ChosenShapeCard cardType={CardType.Any} isEmpty={true}/>}
        </Box>
    )

export default ChosenCards;