import {CardColor} from "./CardColor";

export enum CardType {
    Circle = "circle",
    Square = "square",
    Triangle = "triangle",
    Pentagon = "pentagon",
    Split = "split",
    Any = "any"
}

export const getCardTypeKey = (cardType: CardType, cardColor?: CardColor) => {
    return `${cardType}-${cardColor}`
}

export const colorCardTypes = [
    CardType.Any,
    CardType.Square,
    CardType.Triangle,
    CardType.Circle,
    CardType.Pentagon,
]