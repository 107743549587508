import {CardSelection} from "./types/CardSelection";
import {CardType, colorCardTypes} from "./types/CardType";
import {CardColor} from "./types/CardColor";
import CardSelectionResult from "./types/CardSelectionResult";

export class DeckHandler {

    private static getStartingDeck = () => [
        {card: {cardType: CardType.Split}, isSelected: false},
        ...colorCardTypes.map((cardType) => (
            {card: {cardType: cardType, cardColor: CardColor.Blue}, isSelected: false}
        )),
        ...colorCardTypes.map((cardType) => (
            {card: {cardType: cardType, cardColor: CardColor.Pink}, isSelected: false}
        ))
    ].sort(() => Math.random() - 0.5);

    constructor(public deckState: CardSelection[] = DeckHandler.getStartingDeck()) {

    }

    private pickCard(): CardSelection | undefined {
        const nextCard = this.deckState.find((card) => !card.isSelected)

        if (nextCard) {
            nextCard.isSelected = true
        }

        return nextCard
    }

    private getStationNumber = () => this.deckState.filter((card) => card.isSelected && card.card.cardColor === CardColor.Pink).length;

    public resetDeck() {
        this.deckState = DeckHandler.getStartingDeck();
    }

    public getSelection(): CardSelectionResult {
        let nextCard = this.pickCard();
        let isSplit = false;

        if (nextCard && nextCard.card.cardType === CardType.Split) {
            isSplit = true;
            nextCard = this.pickCard();
        }

        const stationNumber = this.getStationNumber();

        const isEndOfRound = !nextCard || stationNumber === 5;

        return {
            stationNumber: stationNumber,
            selectedCard: nextCard?.card,
            isSplit: isSplit,
            isEndOfRound: isEndOfRound,
        }
    }
}