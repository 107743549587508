import {Box} from "@mui/material";
import React from "react";
import {PencilColor} from "../types/PencilColor";
import {getPencilColorBackgroundPosition} from "../BackgroundPositionCalculator";

export type ColorListCardProps = {
    pencilColor: PencilColor
}

const ColorListCard = ({pencilColor} : ColorListCardProps) => {

    const backgroundPosition = getPencilColorBackgroundPosition(pencilColor);

    return (
        <Box sx={{margin: "4px"}} pl={2} pr={2}>
            <Box className="bga-icon-card"
                 sx={{
                     backgroundPosition: `${backgroundPosition.x}% ${backgroundPosition.y}%`,
                 }}/>
        </Box>
    )
}

export default ColorListCard;