import {PencilColor} from "../types/PencilColor";
import ColorListCard from "./ColorListCard";
import {Box} from "@mui/material";
import React from "react";

interface ColorListProps {
    colors: PencilColor[]
}

const ColorList = ({colors}: ColorListProps) => {

    return (
        <Box display="flex" flexDirection="row" justifyContent={"center"} mt={3} pl={2} pr={2}>
            {colors.map((color) => (
                <ColorListCard key={color} pencilColor={color}/>
            ))}
        </Box>
    )
}

export default ColorList;