import React, {MouseEvent} from "react";
import {Box, Button, Typography} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

type SettingsIconButtonProps = {
    text: String,
    handleIconClick: (event: MouseEvent<HTMLButtonElement>) => void
};

const SettingsIconButton = ({text, handleIconClick}: SettingsIconButtonProps) => {

    return (
        <Box>
            <Button className="profile-icon-button" disableRipple={true}
                    onClick={handleIconClick}>
                <SettingsIcon color="action"/>
                <Typography variant="body2" color="white" fontSize={"0.75em"}>{text}</Typography>
            </Button>
        </Box>
    )
}

export default SettingsIconButton;