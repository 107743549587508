import React from "react";
import ShapeListCard from "./ShapeListCard";
import {Box} from "@mui/material";
import {SelectableStationCard, StationCard} from "../types/StationCard";
import {CardType, getCardTypeKey} from "../types/CardType";
import {CardColor} from "../types/CardColor";

interface ShapeListProps {
    selectedCards: StationCard[],
}

const colorMap = (cardType: CardType, cardColor: CardColor, selectedCards: StationCard[]): SelectableStationCard => (
    {
        cardType: cardType,
        cardColor: cardColor,
        isSelected: selectedCards.some((card) => card.cardType === cardType && card.cardColor === cardColor)
    }
)

const ShapeList = ({selectedCards}: ShapeListProps) => {

    const cardTypes = [
        CardType.Any,
        CardType.Square,
        CardType.Triangle,
        CardType.Circle,
        CardType.Pentagon,
    ]

    const blueCards: SelectableStationCard[] = [
        {cardType: CardType.Split, isSelected: selectedCards.some((card) => card.cardType === CardType.Split)},
        ...cardTypes.map((cardType) => colorMap(cardType, CardColor.Blue, selectedCards)),
    ]

    const pinkCards: SelectableStationCard[] = cardTypes.map((cardType) => colorMap(cardType, CardColor.Pink, selectedCards));

    return (
        <Box>
            <Box justifyContent="center" display={"flex"} flexWrap={"wrap"}>
                {blueCards.map((shapeListCard) => (
                    <ShapeListCard key={getCardTypeKey(shapeListCard.cardType, shapeListCard.cardColor)}
                                   cardType={shapeListCard.cardType} cardColor={shapeListCard.cardColor}
                                   isSelected={shapeListCard.isSelected}/>
                ))}
            </Box>
            <Box justifyContent="center" display={"flex"} flexWrap={"wrap"}>
                {pinkCards.map((shapeListCard) => (
                    <ShapeListCard key={getCardTypeKey(shapeListCard.cardType, shapeListCard.cardColor)}
                                   cardType={shapeListCard.cardType} cardColor={shapeListCard.cardColor}
                                   isSelected={shapeListCard.isSelected}/>
                ))}
            </Box>
        </Box>
    )
}

export default ShapeList;