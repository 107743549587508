import {CardType} from "./types/CardType";
import {CardColor} from "./types/CardColor";
import {PencilColor} from "./types/PencilColor";

export type BackgroundPosition = {
    x: number,
    y: number
}

export function getCardBackgroundPosition(cardType: CardType, cardColor?: CardColor): BackgroundPosition {
    const isBlue: Boolean = cardColor === CardColor.Blue;
    switch (cardType) {
        case "square":
            return isBlue ? { x: 0, y: 0} : { x: -200, y: 50 };
        case "triangle":
            return isBlue ? { x: -100, y: 0} : { x: -300, y: 50 };
        case "pentagon":
            return isBlue ? { x: -200, y: 0} : { x: 0, y: 100 };
        case "circle":
            return isBlue ? { x: -300, y: 0} : { x: -100, y: 100 };
        case "any":
            return isBlue ? { x: 0, y: 50} : { x: -200, y: 100 };
        case "split":
            return { x: -100, y: 50 };
        default:
            return { x: 0, y: 0 };
    }
}

export function getIconCardBackgroundPosition(cardType: CardType): BackgroundPosition {
    switch (cardType) {
        case "square":
            return { x: -100, y: 0 };
        case "triangle":
            return { x: -200, y: 0 };
        case "circle":
            return { x: -300, y: 0 };
        case "pentagon":
            return { x: -400, y: 0 };
        case "any":
            return { x: 0, y: 100 };
        case "split":
            return { x: -100, y: 100 };
        default:
            return { x: 0, y: 0 };
    }
}

export function getPencilColorBackgroundPosition(pencilColor: PencilColor): BackgroundPosition {
    switch (pencilColor) {
        case PencilColor.Purple:
            return { x: -100, y: -100 };
        case PencilColor.Green:
            return { x: -200, y: -100 };
        case PencilColor.Pink:
            return { x: -300, y: -100 };
        case PencilColor.Blue:
            return { x: -400, y: -100 };
        default:
            return { x: 0, y: 0 };
    }
}