import {Box, Button, Typography} from "@mui/material";

type DrawButtonProps = {
    text: string,
    onDrawClicked: () => void
}

const DrawButton = ({text, onDrawClicked}: DrawButtonProps) => {
    return (
        <Box mt={5} display="flex" justifyContent="center">
            <Button sx={{width: "75%", padding: 3}} title={"Draw"} variant="contained" color="warning" onClick={onDrawClicked}>
                <Typography variant={"h4"}>{text}</Typography>
            </Button>
        </Box>
    )
}

export default DrawButton;